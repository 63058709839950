import React, { useState, useEffect } from 'react';
import imgLogo from '../../assets/destaque-black.png';
import { Link, useNavigate } from 'react-router-dom';
import './Login.css';
import SmallButton from '../../Componentes/SmallButton/SmallButton';
import BigButton from '../../Componentes/BigButton/BigButton';
import { FaArrowLeft } from 'react-icons/fa';
import { login } from '../../services/Api';
import InputMask from 'react-input-mask';
import { ValidateCpf } from '../../utils/ValidateCpf';
import Swal from 'sweetalert2';

function Login() {
	const navigate = useNavigate();
	const [cpf, setCpf] = useState('');

	useEffect(() => {
		localStorage.removeItem('token');
		localStorage.removeItem('user');
	}, []);

	const handleCpfChange = (event) => {
		setCpf(event.target.value);
	};

	const handleLogin = async () => {
		if (cpf.length < 14 || !ValidateCpf(cpf)) {
			Swal.fire({
				text: 'CPF inválido.',
				icon: 'error',
			});
			return;
		}
		try {
			await login(cpf);
			navigate('/acesso', { state: { cpf: cpf } });
    } catch (error) {
			if (error.status == 403) {
				Swal.fire({
					text: 'Não encontramos um usuário com esse CPF.',
					icon: 'error',
				});
				return;
			}
			if (error.status == 400) {
				Swal.fire({
					html: 'Sua participação já foi encerrada com sucesso. </br> Você não pode mais votar.',
					title: 'Obrigado!',
					icon: 'success',
				});
				return;
			}
		}
	};

	const handleGoBack = () => {
		navigate('/');
	};

	return (
		<div className="container text-center col-md-6 col-sm-10">
			<div className="row justify-content-between px-2 mt-4">
				<div className="card card-principal col-lg-12 col-md-12 col-sm-12 shadow-lg">
					<div className="card-body col-12 justify-content-center">
						<div className="row justify-content-start mt-0 mb-0">
							<FaArrowLeft className="back-arrow-btn" onClick={handleGoBack} />
						</div>
						<div className="row justify-content-center">
							<img src={imgLogo} alt="logo-destaque" className="img-logo-destaque" />
						</div>
						<h2>Olá</h2>
						<p>Acesse a sua conta.</p>
						<p>Entre com seu CPF para autenticar com um código enviado ao seu email.</p>
						<InputMask mask="999.999.999-99" value={cpf} onChange={handleCpfChange}>
							{(inputProps) => (
								<input
									{...inputProps}
									type="text"
									id="cpf"
									className="cpf-input cpf-input-login"
									placeholder="000.000.000-00"
									onKeyDown={(event) => {
										if (event.key === 'Enter') {
											handleLogin();
										}
									}}
								/>
							)}
						</InputMask>

						<div className="col-12">
							<BigButton onClick={handleLogin} className="login-button">
								ENTRAR
							</BigButton>
						</div>

						<div className="col-12">
							<Link to="/cadastro" className="create-account-link">
								<SmallButton className="create-account-button">CRIAR CONTA</SmallButton>
							</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Login;
