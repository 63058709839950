import { React } from 'react';
import { Link } from 'react-router-dom';
import imgLogo from '../../assets/destaque.png';

import './Home.css';

function Home() {
	return (
		<div class="container-lg text-center">
			<div class="row justify-content-center">
				<div class="col-12">
					<img src={imgLogo} alt="logo-associacao" className="img-logo" />
				</div>
				<div class="col-12">
					<a href="/login">
						<button class="home-button">INICIAR</button>
					</a>
				</div>
			</div>
		</div>
	);
}

export default Home;
