import React, { useEffect, useState } from 'react';
import imgLogo from '../../assets/destaque-black.png';
import './Empresas.css';
import { useLocation, useNavigate } from 'react-router-dom';
import VButton from '../../Componentes/VButton/VButton';
import { FaArrowLeft } from 'react-icons/fa';
import { FaCheck } from 'react-icons/fa';
import { saveVote } from '../../services/Api';
import { localStorageHelper } from '../../utils/LocalStorageHelper';
import Swal from 'sweetalert2';

function CategoriaEmpresas() {
	const location = useLocation();
	const navigate = useNavigate();
	const { categoria } = location.state || { categoria: {} };

	const [searchTerm, setSearchTerm] = useState('');
	const [filteredEmpresas, setFilteredEmpresas] = useState(categoria.companies);
	useEffect(() => {
		const filtradas = categoria.companies.filter((empresa) =>
			empresa.name.toLowerCase().includes(searchTerm.toLowerCase()),
		);
		setFilteredEmpresas(filtradas);
	}, [searchTerm, categoria.companies]);

	const handleVote = async (empresa) => {
		Swal.fire({
			html: `Você deseja mesmo votar na empresa <b>${empresa.name}</b>?</br>Essa ação não poderá ser desfeita.`,
			icon: 'warning',
			showCancelButton: true,
			cancelButtonText: 'Cancelar',
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Sim, votar!',
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					const user = JSON.parse(localStorageHelper.getItem('user'));
					const response = await saveVote({
						idCompany: empresa.id,
						idUser: user.id,
					});
					if (response.status == 201) {
						Swal.fire({
							title: 'Feito!',
							text: 'Seu voto foi salvo.',
							icon: 'success',
						}).then(() => {
							navigate('/perfil');
						});
					}
				} catch (error) {
					console.log(error);
				}
			}
		});
	};

	const handleGoBack = () => {
		navigate('/perfil');
	};

	const handleSearchChange = (event) => {
		setSearchTerm(event.target.value);
	};

	return (
		<div class="container text-center col-md-6 col-sm-10">
			<div class="row justify-content-between px-2">
				<div class="card card-principal col-lg-12 col-md-12 col-sm-12 shadow-lg">
					<div class="card-body col-12 justify-content-center">
						<div className="row justify-content-start mt-0 mb-0">
							<FaArrowLeft className="back-arrow-btn" onClick={handleGoBack} />
						</div>
						<div className="row justify-content-center">
							<img src={imgLogo} alt="logo-destaque" className="img-logo-destaque" />
						</div>
						<h2 className="categoria-title">{categoria.name || 'Desconhecida'}</h2>{' '}
						<p>Escolha a empresa que você deseja votar.</p>
						<div class="search-container">
							<input
								type="text"
								class="search-input"
								placeholder="Pesquisar empresas"
								value={searchTerm}
								onChange={handleSearchChange}
							/>
							<i class="fas fa-search search-icon"></i>
						</div>
						<div className="col-12 p-3">
							<ul class="list-group">
								{filteredEmpresas.length > 0 ? (
									filteredEmpresas.map((empresa, index) => (
										<div key={index} className="empresa-item">
											<li class="list-group-item d-flex justify-content-between align-items-center col-12">
												<div className="col-8">{empresa.name}</div>
												<div className="col-4 align-items-center">
													{empresa.id == categoria.votedCompanyId ? (
														<VButton
															className="voted"
															disabled
															style={{
																backgroundColor: 'white',
																color: 'black',
																border: '1px solid black',
															}}
														>
															Você já votou.
														</VButton>
													) : (
														<VButton onClick={() => handleVote(empresa)}>
															VOTAR <FaCheck style={{ marginLeft: '8px' }} />
														</VButton>
													)}
												</div>
											</li>
										</div>
									))
								) : (
									<p>Nenhuma empresa encontrada.</p>
								)}
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default CategoriaEmpresas;
